<template>
  <b-card-code
    no-body
  >
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      :title="`${recordToDelete.isPermanent ? 'Are you sure you want to permanently delete this record?' : 'Are you sure you want to delete this record?'}`"
      centered
      ok-title="Delete"
      @ok="deleteModalHandler"
    >
      <div>
        {{ recordToDelete.isPermanent ? 'Permanently deleted records cannot be recovered later.' : 'In case you might want to recover it later, you can enable the "With deleted" toggle to find it and either recover or permanently delete it.' }}
      </div>
    </b-modal>
    <b-modal
      id="modal-edit"
      ref="modal"
      title="Edit Hardware item"
      hide-footer
      size="md"
      @hidden="closeModal"
    >
      <p>Are you sure that you want to disassociate item(s)?</p>
      <div class="d-flex justify-content-between align-items-center">
        <b-button
          variant="primary"
          @click="submitDeassociation()"
        >
          <span v-if="!loadingDeassociation">
            Disassociate
          </span>
          <div
            v-if="loadingDeassociation"
            class="spinner-border spinner-border-sm"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="hardware-info"
      ref="hardware-info"
      :title="selectedHardwareModalInfo.name ? 'Site Hardware - ' + selectedHardwareModalInfo.name : 'Site Hardware'"
      size="md"
      ok-title="Edit"
      cancel-title="Close"
      centered
      @ok="toEdit(selectedHardwareModalInfo.id)"
    >
      <div
        v-if="selectedHardwareModalInfo.name"
        class="view-modal"
      >
        <div class="mb-1 align-items-center view-modal__section-title">
          <feather-icon
            icon="ListIcon"
            width="18"
            height="18"
          />
          <h4>
            Hardware specifications
          </h4>
        </div>
        <p>
          Name: <span>{{ selectedHardwareModalInfo.name }}</span>
        </p>
        <p v-if="selectedHardwareModalInfo.model">
          Model: <span>{{ selectedHardwareModalInfo.model.name }}</span>
        </p>
        <p v-if="selectedHardwareModalInfo.serial_number">
          Serial number: <span>{{ selectedHardwareModalInfo.serial_number }}</span>
        </p>
        <p v-if="selectedHardwareModalInfo.warranty_expiry_at">
          Warranty expiry at: <span>{{ new Date(selectedHardwareModalInfo.warranty_expiry_at).toDateString() }}</span>
        </p>
        <p v-if="selectedHardwareModalInfo.site">
          Site: <span>{{ selectedHardwareModalInfo.site.name }}</span>
        </p>
        <p v-if="selectedHardwareModalInfo.comment">
          Comment: <span>{{ selectedHardwareModalInfo.comment }}</span>
        </p>
        <div
          v-if="selectedHardwareModalInfo.client || selectedHardwareModalInfo.reseller || selectedHardwareModalInfo.distributor || selectedHardwareModalInfo.masterDistributor"
          class="view-modal__owners"
        >
          <div class="mb-1 align-items-center view-modal__section-title">
            <feather-icon
              icon="UsersIcon"
              width="18"
              height="18"
            />
            <h4>Owner details</h4>
          </div>
          <p v-if="selectedHardwareModalInfo.client">
            Client: <span>{{ selectedHardwareModalInfo.client.name }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.reseller">
            Reseller: <span>{{ selectedHardwareModalInfo.reseller.name }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.distributor">
            Distributor: <span>{{ selectedHardwareModalInfo.distributor.name }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.masterDistributor">
            Master Distributor: <span>{{ selectedHardwareModalInfo.masterDistributor.name }}</span>
          </p>
        </div>
        <div class="mb-1 align-items-center view-modal__section-title">
          <feather-icon
            icon="InfoIcon"
            width="18"
            height="18"
          />
          <h4>Additional details</h4>
        </div>
        <div v-if="selectedHardwareModalInfo.readOnly">
          <b-badge
            v-if="!selectedHardwareModalInfo.readOnly.is_online"
            pill
            variant="danger"
            class="license-badge mb-1"
          >Offline</b-badge>
          <b-badge
            v-else
            pill
            variant="success"
            class="license-badge mb-1"
          >Online</b-badge>
          <p v-if="selectedHardwareModalInfo.readOnly.current_system_version">
            Current Script Version: <span>{{ selectedHardwareModalInfo.readOnly.current_system_version }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.readOnly.original_router_board_version">
            Original RouterBOARD Version: <span>{{ selectedHardwareModalInfo.readOnly.original_router_board_version }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.readOnly.polled_at">
            Last Polled datetime: <span>{{ new Date(selectedHardwareModalInfo.readOnly.polled_at).toDateString() }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.readOnly.polled_at">
            IP Address: <span>{{ selectedHardwareModalInfo.readOnly.ip_address }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.readOnly.polled_at">
            Last Seen: <span>{{ new Date(selectedHardwareModalInfo.readOnly.last_seen).toDateString() }}</span>
          </p>
        </div>
      </div>
    </b-modal>
    <b-row
      class="px-2 mb-2"
    >
      <b-col
        v-if="$can('create', 'hardware')"
        order-md="2"
        order="1"
        class="text-right"
      >
        <div
          v-if="$can('delete', 'hardware')"
          label-class="label-class"
          class="with-deleted-checkbox"
        >
          <span>With deleted</span>
          <b-form-checkbox
            class="mr-0"
            switch
            inline
            @change="setFilterWithTrashed"
          />
        </div>
        <b-button
          v-if="$can('additional-action-delete', 'hardware')"
          variant="primary"
          class="mr-2"
          @click="toggleFilterOnlyTrashed"
        >
          <span v-if="this.onlyTrashed">Without deleted</span>
          <span v-else>Only deleted</span>
        </b-button>
        <div class="site-hardware-actions">
          <b-button
            v-if="$can('import', 'hardware')"
            to="/hardware/upload"
            variant="primary"
          >
            Import CSV
          </b-button>
          <b-button
            to="/hardware/create"
            variant="primary"
          >
            Create
          </b-button>
          <b-button
            variant="primary"
          >
            <download-csv
              :data="itemsForExport"
            >
              Export View
            </download-csv>
          </b-button>
        </div>
      </b-col>

      <b-col
        md="6"
        order-md="1"
        order="2"
      >
        <b-form @submit.prevent="searchRows()">
          <p v-if="companyId || companyName">
            Company: <a
              :href="`/company-management/summary/${companyId}`"
            >
              {{ companyName }}
            </a>
          </p>
          <b-form-row class="align-items-end">
            <b-col>
              <feather-icon
                v-if="(searchFields && searchFields.length > 0) || (orderBy && orderBy.length > 0) || (sortedBy && sortedBy.length > 0)"
                v-b-tooltip.hover
                icon="XIcon"
                title="Reset filters"
                size="24"
                class="text-body align-middle mr-25"
                @click="handleReset"
              />
            </b-col>
          </b-form-row>
        </b-form>
      </b-col>
    </b-row>
    <b-card-body>
      <table-mobile-solo
        :search="searchValue"
        :handle-search="handleMobileSearch"
        :mobile-search-fields="mobileSearchFields"
        :loading="isLoading"
        :items="items"
        :fields="fields"
        :pagination="{
          pagination: pagination,
          perPage: perPage,
          currentPage: page,
          pageSizeOptions: pageSizeOptions,
          enabled: true,
          perPageChanged: perPageChanged,
          changePage: changePage,
        }"
        :rules="{
          model(value) {
            return value.model.name
          },
          masterDistributor(value) {
            return value.masterDistributor ? value.masterDistributor.name : '-'
          },
          distributor(value) {
            return value.distributor ? value.distributor.name : '-'
          },
          reseller(value) {
            return value.reseller ? value.reseller.name : '-'
          },
          client(value) {
            return value.client ? value.client.name : '-'
          },
          site(value) {
            return value.site ? value.site.name : '-'
          },
          licence(value) {
            return value.licence ? value.licence.name : '-'
          }
        }"
        :key-value="'name'"
        :key-prop="'id'"
        :actions="[{
          label: 'Edit',
          type: 'primary',
          icon: 'Edit2Icon',
          visible: () => $can('update', 'hardware'),
          click: ({id}) => toEdit(id),
        },{
          label: 'Disassociate',
          type: 'primary',
          icon: 'GitPullRequestIcon',
          visible: ({site}) => $can('update', 'hardware') && site,
          click: ({id}) => {
            setIdToDes(id);
            handleModalOpen('modal-edit')
          },
        },{
          label: 'Delete',
          type: 'danger',
          icon: 'TrashIcon',
          visible: ({is_deleted}) => $can('delete', 'companies') && !is_deleted,
          click: ({id}) => deleteHandler(id),
        }, {
          label: 'Permanently delete',
          type: 'danger',
          icon: 'TrashIcon',
          visible: ({is_deleted}) => $can('delete', 'companies') && is_deleted,
          click: ({id}) => deleteHandler(id, true),
        }, {
          label: 'Recover',
          type: 'primary',
          icon: 'RefreshCcwIcon',
          visible: ({is_deleted}) => $can('delete', 'companies') && is_deleted,
          click: ({id}) => recoverRow(id),
        }]"
      />
      <!-- table -->
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        compact-mode
        :columns="fields"
        :rows="items"
        :total-rows="pagination.total"
        class="hardware-table hide-mobile-table"
        :row-style-class="rowStyleClassFn"
        mode="remote"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: orderBy, type: sortedBy}
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage,
          setCurrentPage: page
        }"
        :select-options="{
          enabled: true,
          disableSelectInfo: false,
          selectOnCheckboxOnly: true,
          selectAllByGroup: false,
          selectionInfoClass: 'table-selected-row-info-panel',
        }"
        @on-column-filter="columnFilterFn"
        @on-sort-change="onSortChange"
      >
        <template slot="selected-row-actions">
          <b-button
            v-b-modal.modal-edit
            variant="primary"
            class="ml-auto"
          >
            Disassociate items
          </b-button>
        </template>
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-checkbox-row"
          slot-scope="{ row }"
        >
          <span>
            <input
              :id="'basic_checkbox_'+row.id"
              type="checkbox"
              class="filled-in"
              :checked="row.vgtSelected"
            >
            <label for="'basic_checkbox_'+row.id" />
          </span>
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="$can('update', 'hardware')"
                  @click.prevent="toEdit(props.row.id)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('update', 'hardware') && props.row.site"
                  v-b-modal.modal-edit
                  @click.prevent="setIdToDes(props.row.id)"
                >
                  <feather-icon
                    icon="GitPullRequestIcon"
                    class="mr-50"
                  />
                  <span>Disassociate</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.model.config_file"
                  :href="props.row.model.config_file"
                  exact-path
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span>Download config file</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.model.dpk_file"
                  :href="props.row.model.dpk_file"
                  exact-path
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span>Download build file</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click.prevent="handleRebootItem(props.row.id)"
                >
                  <feather-icon
                    ref="rebootIcon"
                    icon="RefreshCwIcon"
                    class="mr-50"
                  />
                  <span>Reboot</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!props.row.is_deleted && $can('delete', 'hardware')"
                  @click.prevent="deleteHandler(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.is_deleted && $can('delete', 'hardware')"
                  @click.prevent="deleteHandler(props.row.id, true)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Permanently delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.is_deleted && $can('delete', 'hardware')"
                  @click.prevent="recoverRow(props.row.id)"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                    class="mr-50"
                  />
                  <span>Recover</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'name'"
            @click.prevent="onSelectHardwareInfo(props.row.id)"
          >
            <a href="">
              {{ props.formattedRow.name }}
            </a>
          </span>
          <span
            v-else-if="
              props.column.field === 'masterDistributor'
                || props.column.field === 'distributor'
                || props.column.field === 'reseller'
                || props.column.field === 'client'
            "
          >
            <router-link
              v-if="props.row[props.column.field]"
              :to="`/company-management/summary/${props.row[props.column.field].id}`"
            >
              {{ props.row[props.column.field].name }}
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'masterDistributor.name'"
          >
            <router-link
              v-if="props.formattedRow['masterDistributor'] && props.formattedRow['masterDistributor'].is_link"
              :to="`/company-management/summary/${props.formattedRow['masterDistributor'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'distributor.name'"
          >
            <router-link
              v-if="props.formattedRow['distributor'] && props.formattedRow['distributor'].is_link"
              :to="`/company-management/summary/${props.formattedRow['distributor'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'reseller.name'"
          >
            <router-link
              v-if="props.formattedRow['reseller'] && props.formattedRow['reseller'].is_link"
              :to="`/company-management/summary/${props.formattedRow['reseller'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'client.name'"
          >
            <router-link
              v-if="props.formattedRow['client'] && props.formattedRow['client'].is_link"
              :to="`/company-management/summary/${props.formattedRow['client'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'site.name'"
          >
            <span v-if="props.formattedRow['site']">
              <router-link
                :to="`/site/${props.formattedRow['site'].id}/hardware/index`"
              >
                {{ props.formattedRow[props.column.field] }}
              </router-link>
            </span>
            <span
              v-else
              class="w-100 text-center d-block"
            >
              <b-button
                variant="primary"
                class="mx-auto"
                @click.prevent="toEdit(props.row.id)"
              >
                Add site
              </b-button>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'licence'"
          >
            <span v-if="props.row[props.column.field]">
              <router-link
                v-if="props.row[props.column.field]"
                :to="`/licence/update/${props.row[props.column.field].id}`"
              >
                {{ props.row[props.column.field].name }}
              </router-link>
            </span>
            <span
              v-else
              class="w-100 text-center d-block text-danger"
            >
              UNLICENSED
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="perPage"
                :options="pageSizeOptions"
                class="mx-1"
                @change="perPageChanged"
              />
              <span class="text-nowrap"> of {{ pagination.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-if="pagination.last_page !== 1"
                :value="page"
                :total-rows="pagination.total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
    <b-modal
      id="modalSoftDelete"
      ref="modalSoftDelete"
      :title="deleteErrorPopUpInfo.title"
      centered
      ok-only
      ok-variant="secondary"
      ok-title="Cancel"
    >
      <p v-html="deleteErrorPopUpInfo.content" />
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCardBody,
  BPagination,
  BButton,
  BRow,
  BCol,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BForm,
  BFormRow,
  BSpinner,
  VBTooltip,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import IndexPagesMixin from '@/mixins/IndexPages.vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import downloadCsv from 'vue-json-csv'
import { ref, watch } from '@vue/composition-api'
import TableMobileSolo from '@/components/TableMobileSolo.vue'
import axios from '../axios-resolver'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    TableMobileSolo,
    BCardCode,
    BCardBody,
    BButton,
    BPagination,
    BModal,
    BRow,
    BBadge,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BForm,
    BSpinner,
    BFormRow,
    VueGoodTable,
    downloadCsv,
    BFormCheckbox,
  },
  mixins: [
    IndexPagesMixin,
  ],
  data() {
    return {
      searchValue: '',
      mobileSearchFields: {
        'model.name': '',
        comment: '',
        'site.name': '',
      },
      isLoading: true,
      uppdateRouteName: 'hardware-update',
      loadingDeassociation: false,
      currentRoute: this.$route.path,

      deleteErrorPopUpInfo: {},
      selectedHardwareModalInfo: {},
      recordToDelete: {
        id: null,
        isPermanent: false,
      },

      fields: [
        { field: 'name', label: 'Name' },
        {
          field: 'model.name',
          sortable: false,
          mobileSpecial: 'model',
          label: 'Model',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'masterDistributor',
          hidden: true,
        },
        {
          field: 'masterDistributor.name',
          mobileSpecial: 'masterDistributor',
          label: 'Master Dist',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'distributor',
          hidden: true,
        },
        {
          field: 'distributor.name',
          mobileSpecial: 'distributor',
          label: 'Distributor',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'reseller',
          hidden: true,
        },
        {
          field: 'reseller.name',
          mobileSpecial: 'reseller',
          sortable: false,
          label: 'Reseller',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'client',
          hidden: true,
        },
        {
          field: 'client.name',
          mobileSpecial: 'client',
          sortable: false,
          label: 'Client',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'site',
          hidden: true,
        },
        {
          field: 'site.name',
          mobileSpecial: 'site',
          label: 'Site',
          width: '130px',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'licence',
          mobileSpecial: 'licence',
          label: 'Licence',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'serial_number',
          label: 'Serial No.',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'warranty_expiry_at',
          label: 'Warranty Expiry',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'comment',
          label: 'Comment',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'ip_address',
          label: 'IP address',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'action-th-width',
          hidden: !this.$can('update', 'hardware') && !this.$can('delete', 'hardware'),
        },
      ],
    }
  },
  setup() {
    const companyName = ref('')
    const companyId = ref('')

    watch(companyName, companyId, () => {
      if (this.$route.query.companyId) {
        const company = this.getCompany(this.$route.query.companyId)
        this.companyName = company.name
        this.companyId = this.$route.query.companyId
      } else {
        this.companyName = ''
      }
    })
    return {
      companyName: '',
    }
  },
  async mounted() {
    if (this.$route.query.companyId) {
      const company = await this.getCompany(this.$route.query.companyId)
      this.companyName = company.name
    }
  },
  computed: {
    ...mapState('hardware', {
      items: 'items',
      permissions: 'permissions',
      pagination: 'pagination',
      searchFields: 'searchFields',
      orderBy: 'orderBy',
      sortedBy: 'sortedBy',
    }),
    page() {
      return this.$route.query?.params || {}
    },
    itemsForExport() {
      const arr = JSON.parse(JSON.stringify(this.items))
      arr.map(item => {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const property in item) {
          // eslint-disable-next-line no-param-reassign
          if (typeof item[property] === 'object') {
            if (item[property] && item[property].name) {
              // eslint-disable-next-line no-param-reassign
              item[property] = item[property].name
            }
          }
        }
        return item
      })
      return arr
    },
  },
  watch: {
    async $route(to) {
      const additionalPathname = to.fullPath.replace('/hardware/list', '')
      if (!additionalPathname) {
        await this.clearAllData()
        this.companyName = ''
      }
    },
    recordToDelete(val) {
      if (val.id) {
        this.$refs.modalDelete.show()
      } else {
        this.$refs.modalDelete.hide()
      }
    },
    items() {
      this.setVisibleFields()
    },
  },
  methods: {
    ...mapActions('hardware', {
      fetchItems: 'getItems',
      deleteRecord: 'deleteItem',
      setSearchFields: 'setSearchFields',
      setOrderBy: 'setOrderBy',
      setSortedBy: 'setSortedBy',
      rebootItem: 'rebootItem',
      deassociateItems: 'deassociateItems',
      getCompany: 'getCompany',
      recoverRecord: 'recoverItem',
      setJoin: 'setJoin',
    }),
    rowStyleClassFn(row) {
      if (row.is_deleted) {
        return 'deleted-class'
      } if (!row.site) {
        return 'disable-checkbox'
      }
      return ''
    },
    handleModalOpen(modalName) {
      this.$refs[modalName].show()
    },
    handleReset() {
      this.resetFilter()
      this.searchValue = ''
    },
    async handleMobileSearch(search, searchValue) {
      if (this.search !== searchValue) {
        this.searchValue = searchValue
      }

      this.setJoin('or')
      this.columnFilterFn(search, true)
    },
    async deleteHandler(id, isPermanent = false) {
      this.recordToDelete = {
        id,
        isPermanent,
      }
    },
    async deleteModalHandler() {
      const errorRes = await this.deleteRow(this.recordToDelete.id)
      this.deleteErrorPopUpInfo = errorRes
      this.recordToDelete = {
        id: null,
        isPermanent: false,
      }
    },
    async onSelectHardwareInfo(id) {
      try {
        const { data } = await axios.get(`/hardware/${id}/show`)
        this.selectedHardwareModalInfo = { ...data.data, id }
        this.$refs['hardware-info'].show()
      } catch (error) {
        console.warn('Error while fetching hardware info', error)
      }
    },
    setIdToDes(id) {
      this.idToRemove = id

      // open modal
      this.$refs.modal.show()
    },
    setVisibleFields() {
      this.fields = this.fields.map(item => {
        if (item.field === 'masterDistributor' && !this.permissions.includes('masterDistributor')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        if (item.field === 'distributor' && !this.permissions.includes('distributor')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        if (item.field === 'reseller' && !this.permissions.includes('reseller')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        if (item.field === 'client' && !this.permissions.includes('client')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        return item
      })
    },
    async handleRebootItem(id) {
      try {
        await this.rebootItem(id)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
    },
    closeModal() {
      this.$refs.modal.hide()
    },
    async getData() {
      const {
        pageNum, perPage, search, searchFields, orderBy, sortedBy,
      } = this.$route.query
      this.page = pageNum ?? 1
      this.perPage = perPage ?? 25

      const params = {
        ...this.$route.query,
      }

      Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])

      if (search || searchFields || orderBy || sortedBy) {
        await this.fetchtableData({
          ...params,
        })
      } else {
        await this.fetchtableData({
          pageNum: this.page,
          perPage: this.perPage,
          onlyTrashed: this.onlyTrashed,
          withTrashed: this.withTrashed,
        })
      }
      this.setSearchParams(search, searchFields)
      this.setOrderBy(orderBy)
      this.setSortedBy(sortedBy)
    },
    async submitDeassociation() {
      this.loadingDeassociation = true
      try {
        let ids = this.$refs.VueGoodTable.selectedRows.map(item => item.id)
        if (!ids.length) {
          ids = this.idToRemove
        }
        await this.deassociateItems({ ids })
        await this.getData()
        this.$refs.modal.hide()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.loadingDeassociation = false
      }
    },
    ...mapMutations('hardwareTypes', ['RESET_STATE']),
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .hardware-table {
    .deleted-class {
    .vgt-checkbox-col{
      background-color: rgba(255, 0, 0, 0.185) !important;
    }
    .vgt-left-align {
      background-color: rgba(255, 0, 0, 0.185) !important;
    }
  }
  .disable-checkbox {
    .vgt-checkbox-col{
      input{
        display: none;
      }
    }
  }
  .table-selected-row-info-panel{
    display: flex;
    align-items: center;
    padding: 10px;
    a{
      color: red
    }
  }
  .vgt-pull-right{
    float: unset !important;
    margin-left: auto;
  }
  .dark-layout{
    .vgt-select{
      background-color: #283046;
      border-color: #404656;
    }
    .table-selected-row-info-panel{
      background-color: #283046;
      border-color: #404656;
    }
  }
  .vgt-table{
    .vgt-left-align, .vgt-right-align{
      vertical-align: middle !important;
    }
  }
  }
</style>
